import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import loopStyles from 'common/styles';
import styled from 'styled-components';
import { Feature } from 'common/feature';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import YouTubeVideo from 'components/common/YouTubeVideo';

interface RouteLayoutProps {
    feature: Feature,
    classes: any,
}

const RouteLayout = (props: RouteLayoutProps) => {
    // Local State
    const [tabIndex, setTabIndex] = useState(0)

    const { feature } = props;

    const isDetailsEmpty = feature.description === '' || feature.description === undefined

    useEffect(() => {
        setTabIndex(0)
    }, [props.feature])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue)
    }

    const getTabContent = (tabIndex: number) => {
        const { feature } = props;

        if (feature === undefined) { return }

        let content = undefined

        switch (tabIndex) {
            case 0:
                content = <DetailsContainer>
                    {!isDetailsEmpty ? <>
                        {feature.description && <div style={{
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '23px',
                            letterSpacing: '0.1px',
                            color: '#B7B7B7',
                            margin: '10px 20px',
                            overflow: 'hidden',
                        }}>
                            {feature.description}
                        </div>}
                    </> : <EmptyDetailsLabel>
                        Details for this office aren't currently available.
                    </EmptyDetailsLabel>}
                </DetailsContainer>
                break;
        }

        return content
    }

    const { classes } = props;

    // 85 for top padding, 15 for bottom
    return <div style={{ height: 'min-content', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
        <div style={{
            backgroundSize: '100% 100%',
            backgroundColor: '1px solid #434343',
            width: '100%',
        }}>
            {feature?.youtube && <div style={{ height: '225px' }}>
                <YouTubeVideo link={feature?.youtube} />
            </div>}
        </div>  
        {!isDetailsEmpty && <>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                className={classes.tabs}
                TabIndicatorProps={{ style: { backgroundColor: loopStyles.colors.tertiary } }}
            >
                <Tab selected={tabIndex === 0} className={classes.tab} label="Details" />
            </Tabs>
            {getTabContent(tabIndex)}
        </>}
    </div>
}

const EmptyDetailsLabel = styled.div`
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
    color: #B7B7B7;
`

const DetailsContainer = styled.div`
    width: 100%;
    padding: 10px 0;
    height: min-content;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0.4em;
    };
    &::-webkit-scrollbar-thumb {
        background-color: ${loopStyles.colors.tertiary};
        border: 4px solid transparent;
        border-radius: 16px;
    }
`

const styles = {
    tab: {
        width: '100px',
        minWidth: '100px',
        borderRadius: '10px 10px 0 0',
        borderLeft: `1px solid #434343`,
        borderRight: `1px solid #434343`,
        borderTop: `1px solid #434343`,
        color: loopStyles.colors.secondary,
        margin: '0 2px',
        transition: 'all 0.2s ease-in',
        '&.Mui-selected, &:hover': {
            borderLeft: `1px solid ${loopStyles.colors.secondary}`,
            borderRight: `1px solid ${loopStyles.colors.secondary}`,
            borderTop: `1px solid ${loopStyles.colors.secondary}`,
        },
    },
    tabs: {
        marginTop: '8px',
        borderBottom: '1px solid #434343',
        color: 'white',
        '&:selected': {
            color: '#1260cc',
        },
    },
    indicator: {
      backgroundColor: 'none',  
      color: '#1890ff',
    },
}

export default withStyles(styles)(RouteLayout);