import { createSlice } from "@reduxjs/toolkit"

const defaultState = {
    isOpen: false,
    company: [],
    status: [],
    teams: true,
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState: defaultState,
    reducers: {
        updateFilter: (state: any, action) => {
            state[action.payload.key] = action.payload.filter
        },
        toggleFilterMenu: (state: any) => {
            state.isOpen = !state.isOpen
        },
        resetAll: (state: any) => {
            state.company = []
            state.status = []
            state.teams = true
        },
    }
})

export const { updateFilter, toggleFilterMenu, resetAll } = filtersSlice.actions;
export default filtersSlice.reducer;