import aesjs from 'aes-js'
import crypto from 'crypto'
import { MapboxFeature } from 'common/feature';
import mapboxgl from 'mapbox-gl';

export function getRouteIDfromPointFeature(feature: MapboxFeature): string {
    return (feature.properties?.routeID ? feature.properties.routeID : (feature.properties?.featureID && feature.properties!.featureID.split('_').slice(0, 2).join('_')))
}

export async function getAllFeatures() {
    let features: any[] = []

    await fetch(`https://hypermap-bff.herokuapp.com/betafeatures`)
    .then(res => res.text())
    .then(text => {
        let textParts = text.split(':');
        let iv = Buffer.from(textParts[0], 'hex');
        let encryptedText = Buffer.from(textParts[1], 'hex')
        const key = Buffer.concat([Buffer.from(process.env.REACT_APP_ENCRYPTION_KEY!)], 32);
        let decipher = crypto.createDecipheriv(process.env.REACT_APP_ALGO!, key, iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        features = JSON.parse(decrypted.toString()).features
    })
    .catch(error => {
        console.error(error)
    })
    return features
}

export async function getRegionalViews() {
    let response = await fetch("https://immense-tor-12547.herokuapp.com/api/map/views")
    let json = await response.json()
    let views = json.views

    const encryptedBy = aesjs.utils.hex.toBytes(views);
    const aesCtrw = new aesjs.ModeOfOperation.ctr(keyToArray(process.env.REACT_APP_VIEW_KEY!), new aesjs.Counter(5));
    const decryptedBytes = aesCtrw.decrypt(encryptedBy);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return JSON.parse(decryptedText);
}

function keyToArray(key: string) {
    const array: string[] = key.split(',');
    const indexArray: number[] = array.map((item: string) => Number(item))
    return indexArray;
}

export async function loadTeamLayers(map: mapboxgl.Map, allFeatures: MapboxFeature[]) {
    await map.loadImage(
        `${process.env.PUBLIC_URL}/selectedMapTeamIcon.png`,
        await function (error: Error | undefined, image: any) {
            if (error) {
                alert('error loading map icons')
                throw error;
            }

            map.addImage('selectedIcon', image);   
        }
    );
    await map.loadImage(
        `${process.env.PUBLIC_URL}/mapTeamIcon.png`,
        await function (error: Error | undefined, image: any) {
            if (error) {
                console.error('error loading map icons')
                throw error;
            }

            map.addImage('icon', image);
            map.addLayer({
                'id': 'selectedteams',
                'type': 'symbol',
                'source': {
                    'type': 'geojson',
                    "data": {
                        "type": "FeatureCollection",
                        "features": []
                    },
                },
                'layout': {
                    'icon-image': 'icon',
                    'icon-size': 1,
                },
                'paint': {
                    'icon-opacity': 1
                }
            })
            map.addLayer({
                'id': 'teams',
                'type': 'symbol',
                'source': {
                    'type': 'geojson',
                    "data": {
                        "type": "FeatureCollection",
                        "features": allFeatures.filter((feature: MapboxFeature) => feature.geometry.type === 'Point' && feature.properties!['Hyperloop Teams'])
                    },
                },
                'layout': {
                    'icon-image': 'icon',
                    'icon-size': 1,
                },
            })
        }
    )
}

export async function loadLayers(map: mapboxgl.Map, allFeatures: MapboxFeature[]) {
    // console.log(allFeatures)

    map.addSource('allfeatures', {
        "type": "geojson",
        "data": {
            "type": "FeatureCollection",
            "features": allFeatures.filter((feature: MapboxFeature) => feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString')
        },
        'generateId': true,
    })

    map.addLayer({
        "id": 'allfeatures',
        "type": "line",
        "source": 'allfeatures',
        "layout": {
            "line-join": "round",
            "line-cap": "round"
        },
        "paint": {
            "line-color": ['get', 'color'],
            "line-width": 1,
            "line-opacity": [
                'case',
                ['==', ['feature-state', 'selected'], false],
                0.15, 
                1
            ]
        }
    })

    map.addLayer({
        "id": 'selectedfeatures',
        "type": "line",
        "source": {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": []
            },
        },
        "layout": {
            "line-join": "round",
            "line-cap": "round"
        },
        "paint": {
            "line-color": ['get', 'color'],
            "line-width": 1
        }
    })


    // possible to combine with above layer?
    map.addLayer({
        "id": 'points',
        "layout": {

        },
        "type": "circle",
        "source": {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": [],
            },
        },
        "paint": {
            "circle-color": [
                "case",
                [
                  "match",
                  ["get", "companyID"],
                  ["VHO"],
                  true,
                  false
                ],
                "#7d23fb",
                [
                  "match",
                  ["get", "companyID"],
                  ["ZEL"],
                  true,
                  false
                ],
                "hsl(232, 94%, 50%)",
                [
                  "match",
                  ["get", "featureType"],
                  ["team"],
                  true,
                  false
                ],
                "hsla(0, 0%, 0%, 0)",
                [
                  "match",
                  ["get", "companyID"],
                  ["TP"],
                  true,
                  false
                ],
                "#f604f2",
                [
                  "match",
                  ["get", "companyID"],
                  ["HTT"],
                  true,
                  false
                ],
                "#fe0606",
                [
                  "match",
                  ["get", "companyID"],
                  ["BOR"],
                  true,
                  false
                ],
                "hsl(0, 2%, 5%)",
                [
                  "match",
                  ["get", "companyID"],
                  ["HP"],
                  true,
                  false
                ],
                "#fb8209",
                [
                  "match",
                  ["get", "companyID"],
                  ["DGW"],
                  true,
                  false
                ],
                "#22d88f",
                [
                  "match",
                  ["get", "companyID"],
                  ["HAR"],
                  true,
                  false
                ],
                "#13f1d7",
                [
                  "match",
                  ["get", "companyID"],
                  ["SP"],
                  true,
                  false
                ],
                "#b61f13",
                "hsla(0, 0%, 0%, 0)"
            ],
            "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                5,
                3,
                8,
                4.5
            ],
            "circle-stroke-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                0,
                4,
                0,
                5,
                [
                  "match",
                  ["get", "featureType"],
                  ["team"],
                  0,
                  2
                ],
                8,
                [
                  "match",
                  ["get", "featureType"],
                  ["team"],
                  0,
                  2
                ]
              ],
            'circle-stroke-color': '#ffffff'
        }
    })

    map.addLayer({
        "id": 'citynames',
        // "minzoom": 5.6,
        "type": "symbol",
        "source": {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": []
            },
        },
        "layout": {
            "text-field": ['upcase', ['get', 'name']],
            "text-font": [
                "Open Sans Bold",
                "Arial Unicode MS Regular"
            ],
            "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                5,
                2.05,
                14
            ],
            "text-max-width": 10,
            "text-offset": [0, 0.6],
            "text-anchor": "top"
        },
        "paint": {
            "text-color": "#FFFFFF",
        }
    })

    await map.loadImage(
        `${process.env.PUBLIC_URL}/team.png`,
        await function (error: Error | undefined, image: any) {
            if (error) {
                console.error('error loading map icons')
                throw error;
            }
            // console.log('loaded map icon')

            map.addImage('icon', image);
            map.addLayer({
                'id': 'teams',
                'type': 'symbol',
                'source': {
                    'type': 'geojson',
                    "data": {
                        "type": "FeatureCollection",
                        "features": []
                    },
                },
                'layout': {
                    'icon-image': 'icon',
                    'icon-size': 0.05,
                },
            })
            map.addLayer({
                'id': 'selectedteams',
                'type': 'symbol',
                'source': {
                    'type': 'geojson',
                    "data": {
                        "type": "FeatureCollection",
                        "features": []
                    },
                },
                'layout': {
                    'icon-image': 'icon',
                    'icon-size': 0.05,
                },
                'paint': {
                    'icon-opacity': 1
                }
            })
        }
    )
}

// const spreadsheetRanges = ["Master Route Beta", "StationStops", "Teams", "companyData", "Regional Views"]

// export function load(callback: any) {
//     window.gapi.client.load("sheets", "v4", () => {
//         window.gapi.client.sheets.spreadsheets.values
//             .batchGet({
//                 spreadsheetId: process.env.REACT_APP_ROUTES_DATA,
//                 ranges: spreadsheetRanges,
//             })
//             .then(
//                 (response: any) => {
//                     callback(parseData(response));
//                 },
//                 (response: any) => {
//                     console.log(response)
//                 }
//             );
//     });
// }

// function mapRowToFeature(keys: any[], row: any[]){
//     let feature: {[key: string]: any} = {};
//     for (let i = 0; i < keys.length; i++) {
//         feature[keys[i]] = row[i] === "" ? undefined : row[i];
//     }
//     return feature
// }

// function parseData(response: any) {
//     const data = response.result.valueRanges;

//     let routesData = {};

//     spreadsheetRanges.forEach((key: string, index: number) => {
//         const values = data[index].values;
//         const keys = values[0];
//         const rows = values.slice(1);
//         const features = rows.map((row: any) => mapRowToFeature(keys, row))
//         features.forEach((feature: any) => {
//             (routesData as any)[feature.featureID] = feature;
//         })
//     })

//     return routesData;
// }

export async function getNews(callback: any) {
    fetch("https://immense-tor-12547.herokuapp.com/api/map/news")
        .then((response) => {
            let result = response.json()
            return result
        })
        .then((data) => {
            callback(data);
        })
        .catch(() => {
            console.error("News failed to fetch")
        })
}

export function dimLayer(map: mapboxgl.Map, layerID: string){
    if (map?.getLayer(layerID)?.type === 'line') {
        map.setPaintProperty(layerID, 'line-opacity', 0.15);
    } else if (map?.getLayer(layerID)?.type === 'symbol') {
        map.setPaintProperty(layerID, 'icon-opacity', 0.15);
    }

    // map.querySourceFeatures('allfeatures').forEach(f => {
    //     map.setFeatureState({ source: layerID, id: f.id },{ selected: f.properties!.featureID === selectedFeatureID })
    // })

}

export function showLayer(map: mapboxgl.Map, layerID: string) {
    if (map?.getLayer(layerID)?.type === 'line') {
        map.setPaintProperty(layerID, 'line-opacity', 1);
    } else if (map?.getLayer(layerID)?.type === 'symbol') {
        map.setPaintProperty(layerID, 'icon-opacity', 1);
    }

    // map.querySourceFeatures('allfeatures').forEach(f => {
    //     map.setFeatureState({ source: layerID, id: f.id },{ selected: true })
    // })
}

export function hideLayer(map: mapboxgl.Map, layerID: string) {
    map.setLayoutProperty(layerID, 'visibility', 'none');
}

export function unhideLayer(map: mapboxgl.Map, layerID: string) {
    map.setLayoutProperty(layerID, 'visibility', 'visible');
}

export async function getLocation() {
    if (navigator.geolocation)
        navigator.geolocation.getCurrentPosition(sendFoundPosition, sendUnfoundPosition)
    else
        sendUnfoundPosition()
}

async function sendFoundPosition(position: any) {
    const isMobileDevice = typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1
    await fetch("https://immense-tor-12547.herokuapp.com/api/map/geoLogger", {
        method: "POST",
        body: JSON.stringify({ lat: position.coords.latitude, long: position.coords.longitude, mob: isMobileDevice }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

async function sendUnfoundPosition() {
    const isMobileDevice = typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1
    await fetch("https://immense-tor-12547.herokuapp.com/api/map/geoLogger", {
        method: "POST",
        body: JSON.stringify({ lat: 0.0, long: 0.0, mob: isMobileDevice }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


// export async function updateFeatures() {
//     const { allRoutes, routesData } = this.state;
//     const datasetID = "cjyqwzlwh0o2l2onv1hwe34jw";

//     if (allRoutes && routesData) {
//         let features;
//         await fetch(`https://api.mapbox.com/datasets/v1/loopguys/${datasetID}/features?access_token=${process.env.REACT_APP_MAPBOX_SCRIPT}`)
//             .then(response => {
//                 return response.json();
//             }).then(response => {
//                 features = response.features;
//             })

//         features.forEach(feature => {
//             if (!routesData[feature.properties.routename])
//                 return;
//             feature.properties.routeID = routesData[feature.properties.routename].RouteID;
//             fetch(
//                 `https://api.mapbox.com/datasets/v1/loopguys/${datasetID}/features/${feature.id}?access_token=${process.env.REACT_APP_MAPBOX_SCRIPT}`,
//                 {
//                     method: "PUT",
//                     body: JSON.stringify(feature),
//                     headers: {
//                         'Content-Type': 'application/json'
//                     }
//                 }
//             )
//         })
//     }
// }