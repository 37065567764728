import loopStyles from "common/styles"
import { useEffect, useRef, useState } from "react";
import styled from "styled-components"
import CloseIcon from '@mui/icons-material/Close';
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { resetAll, toggleFilterMenu, updateFilter } from "store/reducers/filters";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const statuses = [
    'Concept',
    'Proposal',
    'Approval',
    'Construction',
    'Operational',
    'On Hold',
    'Canceled',
]

type MapCardProps = {
    isOpen: boolean,
}

const MapCard = (props: MapCardProps) => {
    const dispatch = useDispatch()

    const menuData = useSelector((state: any) => state.menu.data)
    const filters = useSelector((state: any) => state.filters)

    const [headerHeight, setHeaderHeight] = useState(0)
    const [isExpanded, setIsExpanded] = useState(false);

    const headerRef = useRef(null)

    const generateFilterChangeHandler = (key: string) => {
        return (ev: any) => {
            const copyOfFilter = new Set(filters[key])
            copyOfFilter.has(ev.currentTarget.id) ? copyOfFilter.delete(ev.currentTarget.id) : copyOfFilter.add(ev.currentTarget.id)
            dispatch(updateFilter({key, filter: Array.from(copyOfFilter)}))
        }
    }

    // set resize observer that updates headerHeight
    useEffect(() => {
        if (!headerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
          // Do what you want to do when the size of the element changes
          setHeaderHeight((headerRef.current as any)?.clientHeight)
        });
        resizeObserver.observe(headerRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [headerRef.current]) // eslint-disable-line

    useEffect(() => {
        if (isOpen === false) {
            setIsExpanded(false)
        }
    }, [props.isOpen]) // eslint-disable-line

    const { isOpen } = props;

    return <AnimatePresence exitBeforeEnter>
        <Container
            height={headerHeight}
            isExpanded={isExpanded}
            isOpen={isOpen}
        >
            <div style={{ padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderBottom: `1px solid rgb(51, 50, 51)` }}>
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <FeatureName>Filters</FeatureName>
                    <IconWrapper onClick={() => { dispatch(toggleFilterMenu()) }}>
                        <CloseIcon/>
                    </IconWrapper>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'end', marginTop: '8px'}}>
                    <div style={{color: '#696974', fontFamily: 'Poppins', fontSize: '14px', paddingRight: '20px'}}>Enable/disable features from the map & menu.</div>
                    <ClearButton disabled={!Object.keys(filters.company).length && !Object.keys(filters.status).length && filters.teams === true} onClick={() => { dispatch(resetAll()) }}>
                        Reset
                        <RestartAltIcon style={{marginLeft: '6px', height: '18px', width: '18px'}}/>
                    </ClearButton>
                </div>
            </div>
            <Scrollable>
                <GroupLabel>Companies</GroupLabel>
                <div style={{padding: '10px 0 0'}}>
                    {filters && menuData && Object.keys(menuData!)
                        .filter(key => menuData[key].name)
                        .sort((a: any, b: any) => Number(menuData[a].menuSortOrder) - Number(menuData[b].menuSortOrder))
                        .map((key) => <FilterButton 
                            id={key} 
                            key={key}
                            selected={filters.company.includes(key)} 
                            color={(loopStyles[key]?.colors.primary || '#FAE900')} 
                            onClick={generateFilterChangeHandler('company')}
                        >
                            {menuData[key].name}
                        </FilterButton>)
                    }
                </div>
                <GroupLabel>Route Status</GroupLabel>
                <div style={{padding: '10px 0 0'}}>
                    {statuses.map((status) => <FilterButton 
                        id={status}
                        key={status}
                        selected={filters.status.includes(status)} 
                        color={loopStyles.colors.tertiary} 
                        onClick={generateFilterChangeHandler('status')}
                    >
                        {status}
                    </FilterButton>)}
                </div>
                <GroupLabel>Academic Teams</GroupLabel>
                <TeamButton onClick={() => { dispatch(updateFilter({key: 'teams', filter: !filters.teams })) }} active={!filters.teams}>
                    <img style={{height: '24px', width: 'auto', marginRight: '10px'}} src={`${process.env.PUBLIC_URL}/team.png`} alt='' />
                    {filters.teams ? 'Show' : 'Hide'} teams
                </TeamButton>
            </Scrollable>
        </Container>
    </AnimatePresence>
}

const ClearButton = styled.button`
    background-color: rgba(0,0,0,0.2);
    color: #FFFFFF;
    border: 0;
    height: min-content;
    font-size: 14px;
    font-family: Poppins;
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    border: 1px solid #454545;
    cursor: pointer;
    &:hover {
        border: 1px solid ${loopStyles.colors.tertiary};
    }
    &:disabled {
        cursor: auto;
        background-color: #343436;
        color: #7B7B7B;
        border: 1px solid transparent;
    }
    ${loopStyles.transition.animationStyles}
`

const Scrollable = styled.div`
    padding: 10px 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    ${loopStyles.scrollbar.default}
`

type TeamButtonProps = {
    active: boolean,
}

const TeamButton = styled.button<TeamButtonProps>`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: Poppins;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
    background-color: #696974;
    border: 1px solid transparent;
    opacity: ${props => props.active ? 1 : 0.5};
    &:hover {
        border: 1px solid rgba(255,255,255,0.25);
    }
    ${loopStyles.transition.animationStyles}
`

type FilterButtonProps = {
    selected: boolean,
    color: string,
}

const FilterButton = styled.button<FilterButtonProps>`
    user-select: none;
    width: calc(50% - 10px);
    font-family: Roboto;
    background-color: transparent;
    color: #fff;
    border: 1px solid ${props => props.selected ? props.color : '#696974'};
    margin: 0px 10px 10px 0px;
    cursor: pointer;
    border-radius: 3px;
    padding: 10px;
    opacity: ${props => props.selected ? 1 : 0.5};
    &:nth-child(even) {
        margin: 0px 0px 10px 10px;
    }
    &:hover {
        border: 1px solid ${props => props.color};
    }
    ${loopStyles.transition.animationStyles}
`

const GroupLabel = styled.div`
    overflow: hidden;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    opacity: 0.8;
`

const FeatureName = styled(motion.div)`
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
`

const IconWrapper = styled.div`
    display: flex;
    border-radius: 8px;
    color: #ffffff;
    align-items: center;
    padding: 4px;
    height: min-content;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    user-select: none;
    &:hover {
        background-color: rgba(0,0,0,0.3);
    }
    ${loopStyles.transition.animationStyles}
`

type ContainerProps = {
    isExpanded: boolean,
    isOpen: boolean,
    height: number,
}

const Container = styled.div<ContainerProps>`
    position: absolute;
    -moz-transition: all .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    -o-transition: all .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    -webkit-transition: all .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: all .4s cubic-bezier(0.25, 0.1, 0.25, 1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #454545;
    background-color: ${loopStyles.colors.primary};
    opacity: ${props => props.isOpen ? 1 : 0};
    z-index: 2;
    overflow: hidden;
    ${loopStyles.mediaQueries.desktop} {
        width: 400px;
        top: 70px;
        left: ${props => props.isOpen ? '310px' : '-110px'};
        // min-height: min-content;
        min-height: 400px;
        max-height: calc(100vh - 100px);
    }
    ${loopStyles.mediaQueries.mobile} {
        top: ${props => props.isOpen ? '80px' : '100dvh'};
        left: 2vw;
        width: 96vw;
        min-height: -webkit-fill-available;
        // min-height: calc(100dvh - ${props => props.height}px);
        height: min-content;
        max-height: calc(100dvh - 100px);
    }
`

export default MapCard