import loopStyles from 'common/styles';
import React from 'react';
import styled from 'styled-components';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';

interface ContactLayoutProps {
    // companyType: CompanyType,
}

class ContactLayout extends React.Component<ContactLayoutProps> {
    render() {
        // const { companyType } = this.props

        return (
            <Container>
                <Header>Get in Touch</Header>
                {/* <Title>Contact Us</Title> */}
                <Text>This industry is built on collaboration. Let’s work together on this. What features do you like or don’t like about our map application? What would you suggest next?</Text>
                <Link href="mailto: zak@hypermap.co">
                    <StyledEmailIcon/>
                    zak@hypermap.co
                </Link>
                <Link href='https://www.instagram.com/hyper_map/' target='_blank'>
                    <StyledInstagramIcon/>
                    @hyper_map
                </Link>
                {/* <img src={`${process.env.PUBLIC_URL}/images/${companyType}.png`} alt={'img'} style={{width: '319px', height: '141px' }} />
                <Header>{companyType === CompanyType.Loop ? loopHeader : hyperloopHeader}</Header>
                <Text>{companyType === CompanyType.Loop ? loopText : hyperloopText}</Text> */}
            </Container>
        );
    }
}

const StyledEmailIcon = styled(EmailIcon)`
    margin-right: 8px;
`

const StyledInstagramIcon = styled(InstagramIcon)`
    margin-right: 8px;
`

const Link = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333231;
    margin: 0 20px;
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
    color: ${loopStyles.colors.secondary};
    font-family: Poppins;
    cursor: pointer;
    &:hover {
        color: ${loopStyles.colors.tertiary};
    }
    border: 1px solid rgba(0,0,0,0.1);
    text-decoration: none;
    // box-shadow: inset 5px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    ${loopStyles.transition.animationStyles}
`

const Header = styled.div({
    margin: '12px 0 0 20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '21px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#828286',
})

const Text = styled.div({
    width: '380px',
    margin: '12px 20px 20px 20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const Container = styled.div({
    width: '100%',
    height: '100%',
})

export default ContactLayout;