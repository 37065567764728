import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Feature } from "common/feature";
import { getDocumentFromCollection } from "common/firestoreUtil";
import firebase from 'firebase/compat/app';

export const fetchMenuData = createAsyncThunk('menu/fetchMenuData', async () => {
    const firestoreDB = firebase.firestore();
    
    const document: Feature | undefined = await getDocumentFromCollection(firestoreDB, "menu", "betamenudata")
    return document;
});

const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        data: undefined,
        isLoading: true,
    } as any,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenuData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchMenuData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchMenuData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message;
            });
    },
})

// export const { } = menuSlice.actions;
export default menuSlice.reducer;