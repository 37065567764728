import { MapboxFeature } from "common/feature"
import { dimLayer, getRouteIDfromPointFeature, loadLayers, showLayer } from "common/functions"
import { createMap, desktopZoomSettings } from "common/mapboxUtil"
import loopStyles from "common/styles"
import { mapFirestoreObjectToArray } from "common/utils"
import { AnimatePresence, motion } from "framer-motion"
import mapboxgl, { MapMouseEvent } from "mapbox-gl"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchMapboxFeatures, selectFeatureViaID, zoomToSelection } from "store/reducers/map"
import styled from "styled-components"

type MapProps = {
    mapboxMap: mapboxgl.Map | null,
    updateMapboxMap: (map: mapboxgl.Map) => void;
}

const Map = (props: MapProps) => {

    const dispatch = useDispatch()

    const segmentRouteState = useSelector((state: any) => state.map.segmentRouteState)
    const mapboxFeatures = useSelector((state: any) => state.map.mapboxFeatures)
    const filters = useSelector((state: any) => state.filters)
    const selectedFeatureData = useSelector((state: any) => state.map.selectedFeatureData)
    const selectedSectionIndex = useSelector((state: any) => state.map.selectedSectionIndex)
    const isMobile = useSelector((state: any) => state.global.isMobile)
    const zoom = useSelector((state: any) => state.map.zoom)
    const highlightFeatureIDs = useSelector((state: any) => state.map.highlightFeatureIDs) || []
    const pathData = useSelector((state: any) => state.map.pathData)

    const [isMapStyleLoaded, setIsMapStyleLoaded] = useState(false)
    const [isMapFeaturesLoaded, setIsMapFeaturesLoaded] = useState(false)
    const [hoveredFeatureIDs, setHoveredFeatureIDs] = useState<string[]>([])

    const mapRef = useRef(null)

    const onMapLoad = async (mapInstance: mapboxgl.Map) => {
        const handleMapMouseMove = (e: MapMouseEvent) => {
            const hoveredFeatures = mapInstance.queryRenderedFeatures(e.point).filter((item: any) => item.layer.id === 'allfeatures' || item.layer.id === 'points' || item.layer.id === 'teams' || item.layer.id === 'selectedteams');
            mapInstance.getCanvas().style.cursor = hoveredFeatures.length ? 'pointer' : '';
    
            const hoveredFeatureIDs = hoveredFeatures.map((feature: any) => feature.properties.featureID.split('_').slice(0, 2).join('_'))
            setHoveredFeatureIDs(hoveredFeatureIDs)
        }

        const handleMapMouseClick = (e: MapMouseEvent) => {
            const features = mapInstance.queryRenderedFeatures(e.point).filter((item: any) => item.layer.id === 'allfeatures' || item.layer.id === 'points' || item.layer.id === 'teams' || item.layer.id === 'selectedteams');
        
            if (features.length) {
                dispatch(selectFeatureViaID({ featureID: features[0].properties!.featureID.split('_').slice(0, 2).join('_') }))
                setHoveredFeatureIDs([])
            }
        }

        // mapInstance.on('click', 'allfeatures', () => { console.log(mapInstance.getCenter()) });
        mapInstance.on('mousemove', 'allfeatures', handleMapMouseMove);
        mapInstance.on('mouseleave', 'allfeatures', handleMapMouseMove);
        mapInstance.on('click', 'allfeatures', handleMapMouseClick);

        setIsMapStyleLoaded(true)
    }

    const createMapboxMap = async () => {
        const { updateMapboxMap } = props;

        let mapInstance: mapboxgl.Map = await createMap(mapRef.current, isMobile);
        updateMapboxMap(mapInstance)

        mapInstance.on('load', async () => {
            onMapLoad(mapInstance);
        })

        mapInstance.on('idle', () => {
            mapInstance.resize()
        })
    }

    useEffect(() => {
        dispatch(fetchMapboxFeatures())
    }, []) // eslint-disable-line

    useEffect(() => {
        createMapboxMap()
    },  [mapRef]) // eslint-disable-line

    useEffect(() => {
        const { mapboxMap } = props;

        if (isMapStyleLoaded && mapboxMap?.getLayer('allfeatures') === undefined) {
            mapboxFeatures && mapboxMap && loadLayers(mapboxMap, Object.values(mapboxFeatures))
                .then(() => {
                    setIsMapFeaturesLoaded(true)
                })
        }
    }, [mapboxFeatures, isMapStyleLoaded]) // eslint-disable-line

    const zoomToRoute = (route: any) => {
        const { mapboxMap } = props

        if (!route) return;
    
        const coordinates = route.geometry.coordinates//.reduce((accumulator: any, linestring: any) => [...accumulator, ...linestring], [])
        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0]
        );
           
        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
          bounds.extend(coord);
        }
           
        mapboxMap?.fitBounds(bounds, isMobile ? {} : desktopZoomSettings);
    }

    const autoZoom = (featureID: any) => {
        const { mapboxMap } = props

        if (mapboxFeatures[featureID]?.geometry.type === 'Point') {
            mapboxMap?.setZoom(10.0)
            mapboxMap?.flyTo({ center: mapboxFeatures[featureID]?.geometry.coordinates, padding: (isMobile ? {} : desktopZoomSettings.padding) as any })
        } else {
            // Zoom to section
            if (selectedFeatureData.segmentZooms?.route && !!Object.keys(selectedFeatureData.segmentZooms?.route).length) {
                if (selectedFeatureData.segmentZooms.route) {
                    mapboxMap?.fitBounds(
                        mapFirestoreObjectToArray(selectedFeatureData.segmentZooms.route as any) as mapboxgl.LngLatBoundsLike, 
                        isMobile ? {} : desktopZoomSettings
                    )
                } else {
                    // error handling
                    console.error(`no zoom for ${selectedFeatureData.featureID}`)
                }
                
            } else {
                zoomToRoute(mapboxFeatures[featureID]);
            }
        }    
    }

    const getHighlightedPointFeatures = () => {
        // List all mapbox Point features
        const points = Object.values(mapboxFeatures).filter((feature: any) => feature.geometry.type === "Point")

        // If highlight features are listed, return only those
        if (highlightFeatureIDs.length) {
            // console.log(highlightFeatureIDs)
            return points.filter((p: any) => highlightFeatureIDs.includes(p.properties.featureID))
        }

        if (pathData && pathData !== '') {
            const pathAsArr = pathData.ids.split(',')
            return Object.values(mapboxFeatures)
                .filter((feature: any) => feature.geometry.type === "Point")
                .filter((feature: any) => pathAsArr.includes(feature.properties.featureID))
        }

        // map selected options to the station IDs associated with that option, then reduce to a 1D array of station IDs
        const activeSegmentStationIDs = segmentRouteState
            ?.map((optionValue: number, sectionIndex: number) => Array.from(new Set(selectedFeatureData.segmentData[sectionIndex][optionValue]?.childFeatures)))
            .flat()
            .filter((featureID: string) => featureID && !featureID?.includes('_L'))
        
        
        const filterHighlightedPoints = ((point: any) => {
            const isActiveSegmentStation = !!activeSegmentStationIDs?.length && activeSegmentStationIDs.indexOf(point.properties!.featureID) > -1
            // const isHighlightedPoint = !selectedFeatureData?.segmentData && getRouteIDfromPointFeature(point) && highlightIDs.indexOf(getRouteIDfromPointFeature(point)) > -1
            const isHighlightedPoint = !selectedFeatureData?.segmentData && getRouteIDfromPointFeature(point)?.includes(selectedFeatureData?.featureID)
            const isHiddenTeam = point.properties!.companyID === 'AHT' && (filters.teams || selectedFeatureData?.featureID !== point.properties.featureID)

            // Teams
            if (point.properties!.companyID === 'AHT' && (!filters.teams || selectedFeatureData?.featureID === point.properties.featureID)) {
                return true;
            }

            return !isHiddenTeam && (isActiveSegmentStation || isHighlightedPoint)
        })

        const filtered = points.filter(filterHighlightedPoints)
        return filtered
    }

    const getHighlightedRouteFeatures = () => {
        // map segment route state to the feature ID of each selected option
        const activeSegmentIDs = segmentRouteState
            ?.map((optionValue: number, sectionIndex: number) => Array.from(new Set(selectedFeatureData.segmentData[sectionIndex][optionValue]?.childFeatures)))
            .flat()
            .filter((featureID: string) => featureID.includes('_L'))

        const getParentID = (r: any) => r.properties?.featureID.split('_').slice(0, 2).join('_')
        const filterActiveOptionRoutes = (route: any) => {
            // if the segment is part of the currently selected route
            if (getParentID(route) === selectedFeatureData?.featureID) {
                return activeSegmentIDs?.includes(route.properties.featureID)
            }

            return !selectedFeatureData
        }

        // List all mapbox route features
        const routes = Object.values(mapboxFeatures).filter((feature: any) => feature.geometry.type !== "Point")
        const hoveredRoutes = routes.filter((route: any) => hoveredFeatureIDs.some(id => route.properties.featureID.includes(id)))

        // If highlight features are listed, return only those
        if (highlightFeatureIDs.length) {
            return [...hoveredRoutes.filter(filterActiveOptionRoutes), ...routes.filter((r: any) => highlightFeatureIDs.includes(r.properties.featureID))]
        }

        if (pathData) { 
            // console.log('path reoutes')
            const pathAsArr = pathData.ids.split(',')
            return Object.values(mapboxFeatures)
                .filter((feature: any) => feature.geometry.type !== "Point")
                .filter((feature: any) => pathAsArr.includes(feature.properties.featureID))
        }

        const filteredCompanyRoutes = filters.company.length ? routes.filter((route: any) => filters.company.includes(route.properties.companyID)) : routes;
        const filteredStatusRoutes = filters.status.length ? filteredCompanyRoutes.filter((route: any) => filters.status.includes(route.properties.status)) : filteredCompanyRoutes;

        const highlightedRoutes = routes.filter((route: any) => highlightFeatureIDs.indexOf(getParentID(route)) > -1)

        const selectedRoutes = routes.filter((route: any) => getParentID(route) === selectedFeatureData?.featureID)
        const displayRoutes = [...(selectedRoutes.length ? selectedRoutes : filteredStatusRoutes), ...highlightedRoutes, ...hoveredRoutes].filter(filterActiveOptionRoutes)

        displayRoutes.push(...hoveredRoutes)
        return displayRoutes
    }

    useEffect(() => {
        const { mapboxMap } = props;

        if (isMapFeaturesLoaded) {
            if (isMobile) {
                mapboxMap?.fitBounds([[-139.385222, 60], [-20.313387, -56.577641]])
            } else {
                mapboxMap?.fitBounds([[160.24709996312168, -40.66482502632688], [-130, 67.66452943347628]])
            }
        }
    }, [isMapFeaturesLoaded]) // eslint-disable-line

    useEffect(() => {
        if (selectedFeatureData) {
            autoZoom(selectedFeatureData.featureID)
        }
    }, [selectedFeatureData]) // eslint-disable-line

    useEffect(() => {
        if (pathData?.zoom && Object.values(pathData.zoom).length) {
            const { mapboxMap } = props;

            mapboxMap?.fitBounds(
                mapFirestoreObjectToArray(pathData.zoom as any) as mapboxgl.LngLatBoundsLike, isMobile ? undefined : desktopZoomSettings
            )
        }
    }, [pathData]) // eslint-disable-line

    // Update route display
    useEffect(() => {
        const { mapboxMap } = props;

        const map = mapboxMap as any

        if (!mapboxFeatures || !mapboxMap) return;

        const displayPoints = getHighlightedPointFeatures();
        const displayCities = (displayPoints as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID !== 'AHT')
        const displayTeams = (displayPoints as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT')
        const displayFeatures = getHighlightedRouteFeatures();

        // console.log(displayCities);
        // console.log(displayFeatures);
        // console.log(displayTeams);

        (map?.getSource('selectedfeatures'))?.setData({
            "type": "FeatureCollection",
            "features": displayFeatures
        })

        // map.resize();
        if (displayFeatures?.length === 0 && displayPoints.length === 0 && filters.company.length === 0) {
            showLayer(map, 'allfeatures')
            showLayer(map, 'teams')

            map?.getSource('points')?.setData({
                type: "FeatureCollection",
                features: []
            })
            map?.getSource('citynames')?.setData({
                type: "FeatureCollection",
                features: []
            })
            map?.getSource('teams')?.setData({
                type: "FeatureCollection",
                features: filters.teams ? [] : (Object.values(mapboxFeatures) as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT')
            })
            map?.getSource('selectedteams')?.setData({
                type: "FeatureCollection",
                features: []
            })
        } else {
            dimLayer(map, 'allfeatures');
            dimLayer(map, 'teams');

            map?.getSource('points')?.setData({
                "type": "FeatureCollection",
                "features": displayCities
            })
            map?.getSource('citynames')?.setData({
                "type": "FeatureCollection",
                "features": displayCities
            })
            map?.getSource('teams')?.setData({
                type: "FeatureCollection",
                features: filters.teams ? [] : (Object.values(mapboxFeatures) as MapboxFeature[]).filter((feature: MapboxFeature) => feature.properties!.companyID === 'AHT' && !displayTeams.some(team => team.properties!.featureID === feature.properties!.featureID))
            })
            map?.getSource('selectedteams')?.setData({
                type: "FeatureCollection",
                features: displayTeams,
            })
        }
    }, [selectedFeatureData, selectedSectionIndex, segmentRouteState, filters, highlightFeatureIDs, pathData, hoveredFeatureIDs]) // eslint-disable-line

    useEffect(() => {
        const { mapboxMap } = props;

        if (zoom === true) {
            /*if (highlightFeatureIDs.length) {
                
            } else*/ if (selectedSectionIndex !== undefined) {
                mapboxMap?.fitBounds(
                    mapFirestoreObjectToArray(selectedFeatureData.segmentZooms.sections[selectedSectionIndex]), 
                    isMobile ? {} : desktopZoomSettings
                )
            } else if (mapboxMap && selectedFeatureData) {
                autoZoom(selectedFeatureData.featureID)
            }
            //set zoom to false in Redux
            dispatch(zoomToSelection())
        }
    }, [zoom]) // eslint-disable-line
    
    return <MapContainer ref={mapRef}>
        <AnimatePresence>
            {!isMapFeaturesLoaded && <motion.div 
                initial={{opacity: 1}}
                exit={{opacity: 0}}
                animate={{opacity: 1}}
                style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', top: 0, left: 0, zIndex: 9999, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            >
                <ScreenContainer>
                    <SpinnerContainer id='Spinner' animate={{ scale: [1.0, 1.2, 1.0] }} transition={{ ease: 'easeInOut', duration: 4, repeat: Infinity }}>
                        <Spinner src={`${process.env.PUBLIC_URL}/hyperloopFav.png`} alt={'HyperMap Logo'} />
                    </SpinnerContainer>
                </ScreenContainer>
            </motion.div>}
        </AnimatePresence>
    </MapContainer>
}

const Spinner = styled.img`
  width: 250px;
  height: 250px;
  position: relative;
`

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${loopStyles.mediaQueries.mobile} {
    width: 100vw;
    height: 100vh;
    max-height: 100dvh;
  }
  align-items: center;
  justify-content: center;
  background-color: ${loopStyles.colors.primary};
`

const SpinnerContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
`

const MapContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    outline: none;
    position: relative;
    z-index: 1;
`

export default Map