import loopStyles from "common/styles"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { createAccount, login, resetPassword, resendVerfication, logout } from "store/reducers/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AnimatePresence, motion } from "framer-motion";

const motionFadeStretchProps = {
    initial: {
        opacity: 0,
        height: 0,
    },
    exit: {
        opacity: 0,
        height: 0,
    },
    animate: {
        opacity: 1,
        height: 'auto',
    },
    transition: {
        duration: 0.5,
        ease: 'easeInOut'
    }
}

enum LoginContent {
    CreateAccount,
    ResetPassword
}

type LoginProps = {
    analytics: any,
}

const Login = (props: LoginProps) => {
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.user.user)
    const isLoading = useSelector((state: any) => state.user.isLoading)
    const error = useSelector((state: any) => state.user.loginError)

    // Component state
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [loginContent, setLoginContent] = useState<LoginContent | undefined>(undefined)
    const [isCreateSent, setIsCreateSent] = useState(false)
    const [isResetSent, setIsResetSent] = useState(false)

    const handleLoginClick = (ev: any) => {
        ev.preventDefault()
        ev.stopPropagation();
        dispatch(login({ email, password }))
    }
    
    const handleLogoutClick = (ev: any) => {
        ev.preventDefault()
        ev.stopPropagation();
        dispatch(logout())
    }
    
    const handleResendVerificationClick = (ev: any) => {
        ev.preventDefault()
        ev.stopPropagation();
        dispatch(resendVerfication(user))
    }

    const handleCreateAccountClick = (ev: any) => {
        ev.preventDefault()
        ev.stopPropagation();
        dispatch(createAccount({ email, password }))
        setIsResetSent(true)
    }
    
    const handleResetPasswordClick = (ev: any) => {
        ev.preventDefault()
        ev.stopPropagation();
        dispatch(resetPassword({ email }))
        setIsResetSent(true)
    }

    useEffect(() => {
        setEmail('')
        setPassword('')
        setIsCreateSent(false)
        setIsResetSent(false)
    }, [ loginContent ])

    useEffect(() => {
        props.analytics.logEvent(`Login loaded`);
    }, []) // eslint-disable-line

    console.log(isLoading)
    console.log(loginContent)

    const getContent = () => {
        if (user && !user.emailVerified) {
            return <>
                <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>You're logged in, but you haven't verified your account.</div>
                <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Please check your email for verifcation, and refresh this page when you've completed it.</div>
                <Button onClick={handleResendVerificationClick}>Re-send verification email</Button>
                <div style={{ display: 'flex', marginTop: '40px', textAlign: 'center', color: loopStyles.colors.secondary, fontFamily: 'Poppins'}}>Or</div>
                <Button onClick={handleLogoutClick}>Log Out</Button>
            </>
        }

        switch (loginContent) {
            case LoginContent.CreateAccount:
                return <ContentContainer key='create' {...motionFadeStretchProps} onSubmit={handleCreateAccountClick}>
                    {isCreateSent ? <>
                        <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>We've sent an email to: {email}.</div>
                        <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>You'll need to confirm your account before you can access Hypermap.</div>
                    </> : <>
                        <div style={{display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '40px', fontFamily: 'Poppins', fontSize: '24px'}}>Create Account</div>
                        <InputContainer>
                            <Input value={email} onChange={(ev: any) => { setEmail(ev.target.value) }} placeholder='Email'/>
                        </InputContainer>
                        <InputContainer>
                            <Input value={password} onChange={(ev: any) => { setPassword(ev.target.value) }} type='password' placeholder='Password'/>
                        </InputContainer>
                        <InputContainer>
                            <Input value={password2} onChange={(ev: any) => { setPassword2(ev.target.value) }} type='password' placeholder='Password (again)'/>
                        </InputContainer>
                        <Button type='submit'>Create Account</Button>
                    </>}
                    <Link onClick={() => { setLoginContent(undefined) }}>Back</Link>
                </ContentContainer>
            case LoginContent.ResetPassword:
                return <ContentContainer key='reset' {...motionFadeStretchProps} onSubmit={handleResetPasswordClick}>
                    {isResetSent ? <>
                        <div style={{ display: 'flex', textAlign: 'center', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>If {email} is currently registered, you will receieve an email that will prompts a password reset.</div>
                    </> : <>
                        <div style={{display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '40px', fontFamily: 'Poppins', fontSize: '24px'}}>Reset Password</div>
                        <InputContainer>
                            <Input value={email} name='email' onChange={(ev: any) => { setEmail(ev.target.value) }} id='login-username' placeholder='Email'/>
                        </InputContainer>
                        <Button type='submit'>Reset Password</Button>
                    </>}
                    <Link onClick={() => { setLoginContent(undefined) }}>Back</Link>
                </ContentContainer>
            default:
                return <ContentContainer key='login' {...motionFadeStretchProps} onSubmit={handleLoginClick}>
                    <div style={{display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '40px', fontFamily: 'Poppins', fontSize: '24px'}}>Login</div>
                    <InputContainer>
                        {/* <div style={{width: '100%', display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Email</div> */}
                        <Input value={email} name='email' onChange={(ev: any) => { setEmail(ev.target.value) }} id='username' placeholder='Email'/>
                    </InputContainer>
                    <InputContainer>
                        {/* <div style={{width: '100%', display: 'flex', justifyContent: 'start', color: loopStyles.colors.secondary, marginBottom: '10px', fontFamily: 'Poppins'}}>Password</div> */}
                        <Input value={password} name='password' onChange={(ev: any) => { setPassword(ev.target.value) }} id='password' type='password' placeholder='Password'/>
                    </InputContainer>
                    {error && <Error>{error.code === "auth/wrong-password" ? 'Incorrect password.' : 'An error occured.'}</Error>}
                    {(isLoading || user) ? <CircularProgress style={{ marginTop: '40px', color: loopStyles.colors.tertiary, height: '48px' }} /> : <Button type='submit'>LOGIN</Button>}
                    <Link onClick={() => { setLoginContent(LoginContent.ResetPassword) }}>Reset Password</Link>
                    <Link onClick={() => { setLoginContent(LoginContent.CreateAccount) }}>Create Account</Link>
                </ContentContainer>
        }
    }

    return <Container>
        <div style={{ width: 'calc(100% - 64px)', padding: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '40px'}}>
                <Logo />
                <div style={{ color: loopStyles.colors.tertiary, fontFamily: 'OneDay', fontSize: '48px', lineHeight: '36px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    HyperMap
                </div>
            </div>
            <AnimatePresence exitBeforeEnter>
                {getContent()}
            </AnimatePresence>
        </div>
    </Container>
}

const ContentContainer = styled(motion.form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    min-height: 440px;
    width: 100%;
`

const Error = styled.div`
    color: #ff1212;
    font-family: Poppins;
`

const InputContainer = styled.div`
    ${loopStyles.mediaQueries.mobile} {
        width: 100%;
    }
`

const Input = styled.input`
    color: ${loopStyles.colors.secondary};
    background-color: #343436 !important;
    border-radius: 6px;
    border: 1px solid rgba(255,255,255,0.1);
    border-color: rgba(255,255,255,0.1);
    outline: 0 !important;
    padding: 10px;
    font-family: Poppins !important;
    font-size: 16px;
    margin: 0 0 20px 0;
    outline: none;
    ${loopStyles.mediaQueries.desktop} {
        width: 300px;
        max-width: 320px;
    }
    ${loopStyles.mediaQueries.mobile} {
        width: calc(100% - 22px);
    }
    -moz-transition: border .2s ease-in;
    -o-transition: border .2s ease-in;
    -webkit-transition: border .2s ease-in;
    transition: border .2s ease-in;
    &&:-webkit-autofill {
        border-color: grey;
        font-family: Poppins !important;
        background-color: #343436 !important; /* Your desired background color */
        -webkit-box-shadow: 0 0 0px 1000px #343436 inset !important; /* Match the background color */
        -webkit-text-fill-color: ${loopStyles.colors.secondary} !important; /* Optional: to control text color */
        &:focus {
            border: 1px solid ${loopStyles.colors.tertiary} !important;
            border-color: ${loopStyles.colors.tertiary} !important;
        }
    }
    &:hover {
        border: 1px solid rgba(0, 247, 254, 0.4);
    }
    &:focus {
        border: 1px solid ${loopStyles.colors.tertiary} !important;
        border-color: ${loopStyles.colors.tertiary} !important;
    }
`

const Button = styled.button`
    cursor: pointer;
    background-color: ${loopStyles.colors.tertiary};
    color: ${loopStyles.colors.primary};
    border: 1px solid transparent;
    ${loopStyles.mediaQueries.desktop} {   
        margin-top: 40px;
        min-width: 320px;
        font-size: 1rem;
    }
    ${loopStyles.mediaQueries.mobile} {   
        margin-top: 20px;
        width: calc(100% - 20px);
        max-width: 100%;
        font-size: 1rem;
    }
    min-height: 48px;
    border-radius: 500px;
    font-size: 20px;
    white-space: nowrap;
    font-family: Poppins;
    letter-spacing: 0.5px;
    &:hover {
        color: ${loopStyles.colors.tertiary};
        background-color: #343436;
        border: 1px solid ${loopStyles.colors.tertiary};
    }
    ${loopStyles.transition.animationStyles}
`

const Link = styled.div`
    color: ${loopStyles.colors.tertiary};
    // text-decoration: underline;
    font-family: Poppins;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    min-height: 40px;
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    user-select: none;
    ${loopStyles.mediaQueries.desktop} {
        width: 200px;
        border: 1px solid rgba(0, 247, 254, 0.4);
        &:hover {
            border: 1px solid ${loopStyles.colors.tertiary};
        }
    }
    ${loopStyles.mediaQueries.mobile} {
        width: 100%;
        max-width: 100%;
    }
    ${loopStyles.transition.animationStyles}
    // border: 1px solid ${loopStyles.colors.tertiary};
`

const Logo = styled.div`
    background-image: url(${process.env.PUBLIC_URL}/hyperloopFav.png);
    width: 160px;
    height: 160px;
    margin-bottom: 40px;
    background-size: cover;
`

const Container = styled.div`
    position: absolute;
    width: calc(100vw - 2px);
    height: calc(100vh - 2px);
    max-height: calc(100dvh - 2px);
    top: 0;
    left: 0;
    background-color: ${loopStyles.colors.primary};
    display: flex;
    // border: 1px solid green;
    flex-direction: column;
    align-items: center;
    ${loopStyles.mediaQueries.desktop} {
        justify-content: center;
        height: 100%;
    }
    ${loopStyles.mediaQueries.mobile} {
        // height: 100lvh;
        justify-content: center;
        color: white;
    }
`

export default Login