import mapboxgl from "mapbox-gl";
import { defaultMapSettings, defaultMobileMapSettings } from "common/constants";

export const desktopZoomSettings = {
  padding: {
    right: 40,
    top: 40,
    left: 440,
    bottom: 40
  }
}

export const createMap = async (container: any, isMobile: boolean) => {
    return (new mapboxgl.Map({
      ...(isMobile ? defaultMobileMapSettings : defaultMapSettings),
      container,
    }))
};