import loopStyles from "common/styles";
import { AnimatePresence, motion } from "framer-motion"
import { useSelector } from "react-redux";
import styled from "styled-components"

type SidebarProps = {
    isOpen: boolean,
    fullscreen?: boolean,
    onClose: () => void,
    anchorRight?: boolean,
    children?: any,
}

const Sidebar = (props: SidebarProps) => {
    const isMobile = useSelector((state: any) => state.global.isMobile)

    const { isOpen, onClose, children, fullscreen, anchorRight } = props;

    const initialX = isMobile ? (anchorRight ? 300 : -300) : 0

    return <AnimatePresence exitBeforeEnter>
        {isOpen && (fullscreen ? <Container
            onClick={onClose}
            anchorRight={anchorRight}
            {...loopStyles.motion.fadeInOut}
        >
            <SidebarContainer
                initial={{ x: initialX, opacity: 0 }}
                exit={{ x: initialX, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.3, }}
                onClick={(ev) => { ev.stopPropagation() }}
            >
                {children}
            </SidebarContainer>
        </Container> : <SidebarContainer
            initial={{ x: initialX, opacity: 0 }}
            exit={{ x: initialX, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, }}
            onClick={(ev) => { ev.stopPropagation() }}
        >
            {children}
        </SidebarContainer>)}
    </AnimatePresence>
}

const SidebarContainer = styled(motion.div)`
    width: 300px;
    height: 100%;
    background-color: ${loopStyles.colors.primary};
    display: flex;
    flex-direction: column;
    z-index: 1000;
`

type ContainerProps = {
    anchorRight?: boolean,
}

const Container = styled(motion.div)<ContainerProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100dvh;
    display: flex;
    ${props => props.anchorRight && `justify-content: end;`}
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
`

export default Sidebar