import React from 'react';
import styled from 'styled-components';
import { ReactComponent as AverageSpeed } from 'common/svgIcons/AverageSpeed.svg';
import { ReactComponent as EnergyComparisons } from 'common/svgIcons/EnergyComparisons.svg';
import { ReactComponent as EstimatedAnnualEnergyConsumption } from 'common/svgIcons/EstimatedAnnualEnergyConsumption.svg';
import { ReactComponent as EstimatedCO2Emissions } from 'common/svgIcons/EstimatedCO2Emissions.svg';
import { ReactComponent as EstimatedVehiclesOffRoads } from 'common/svgIcons/EstimatedVehiclesOffRoads.svg';
import { ReactComponent as LengthOfRoute } from 'common/svgIcons/LengthOfRoute.svg';
import { ReactComponent as NumberOfStations } from 'common/svgIcons/NumberOfStations.svg';
import { ReactComponent as TravelTime } from 'common/svgIcons/TravelTime.svg';
import loopStyles from 'common/styles';
import { motion } from 'framer-motion';


interface AboutLayoutProps {
    // companyType: CompanyType,
}

class AboutLayout extends React.Component<AboutLayoutProps> {
    render() {
        // const { companyType } = this.props

        return (
            <Container>
                {/* <Header>What do we do?</Header> */}
                <Title>We aggregate publicly available Hyperloop information.</Title>
                <Text>Our vision is to empower the Hyperloop indsutry through digital interactions with people, data and insights.</Text>

                <Topic>Riding Specs</Topic>
                <Row transition={{staggerChildren: 4}}>
                    <Stat {...loopStyles.motion.fadeInOut}>
                        <Icon>
                            <TravelTime style={{maxWidth: '30px', maxHeight: '30px'}}/>
                        </Icon>
                        Travel Time
                    </Stat>
                    <Stat {...loopStyles.motion.fadeInOut}>
                        <Icon>
                            <AverageSpeed style={{maxWidth: '30px', maxHeight: '30px'}}/>
                        </Icon>
                        Average Speed
                    </Stat>
                    <Stat {...loopStyles.motion.fadeInOut}>
                        <Icon>
                            <NumberOfStations style={{maxWidth: '30px', maxHeight: '30px'}}/>
                        </Icon>
                        Number of Stations
                    </Stat>
                    <Stat {...loopStyles.motion.fadeInOut}>
                        <Icon>
                            <LengthOfRoute style={{maxWidth: '30px', maxHeight: '30px'}}/>
                        </Icon>
                        Length of Route
                    </Stat>
                </Row>
                <Topic>Enviromental Impact</Topic>
                <Row>
                    <Stat {...loopStyles.motion.fadeInOut}>
                        <Icon>
                            <EstimatedCO2Emissions style={{maxWidth: '30px', maxHeight: '30px'}}/>
                        </Icon>
                        Estimated CO2 Emissions
                    </Stat>
                    <Stat {...loopStyles.motion.fadeInOut}>
                        <Icon>
                            <EstimatedVehiclesOffRoads style={{maxWidth: '30px', maxHeight: '30px'}}/>
                        </Icon>
                        Estimated Vehicles off Roads
                    </Stat>
                    <Stat {...loopStyles.motion.fadeInOut}>
                        <Icon>
                            <EstimatedAnnualEnergyConsumption style={{maxWidth: '30px', maxHeight: '30px'}}/>
                        </Icon>
                        Estimated Annual Energy Consumption
                    </Stat>
                    <Stat {...loopStyles.motion.fadeInOut}>
                        <Icon>
                            <EnergyComparisons style={{maxWidth: '30px', maxHeight: '30px'}}/>
                        </Icon>
                        Energy Comparisons
                    </Stat>
                </Row>
                {/* <Topic>Milestones</Topic> */}
                {/* <img src={`${process.env.PUBLIC_URL}/images/${companyType}.png`} alt={'img'} style={{width: '319px', height: '141px' }} />
                <Header>{companyType === CompanyType.Loop ? loopHeader : hyperloopHeader}</Header>
                <Text>{companyType === CompanyType.Loop ? loopText : hyperloopText}</Text> */}
            </Container>
        );
    }
}

const Icon = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
})

const Row = styled(motion.div)({
    display: 'flex',
    justifyContent: 'space-around',
})

const Stat = styled(motion.div)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    width: '15%',
    textAlign: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    letterSpacing: '0.1px',
    color: '#B7B7B7',
    opacity: 0.99,
})

const Topic = styled.div({
    margin: '12px 20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#C0C0C0',
})

const Title = styled.div`
    margin: 10px 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    opacity: 0.99;
    ${loopStyles.mediaQueries.desktop} {
        font-size: 24px;
    }
    ${loopStyles.mediaQueries.mobile} {
        font-size: 24px;
    }
`

const Text = styled.div`
    margin: 12px 0 20px 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #B7B7B7;
    opacity: 0.99;
`

const Container = styled.div`
    max-width: 720px;
    height: 100%;
    padding: 20px;
`

export default AboutLayout;