import loopStyles from "common/styles";
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

type ModalProps = {
    isOpen: boolean,
    onClose: () => void,
    children?: React.ReactNode,
}

const Modal = (props: ModalProps) => {
    const { isOpen, onClose, children } = props;

    return <AnimatePresence exitBeforeEnter>
        {isOpen && <Container
            onClick={onClose}
            {...loopStyles.motion.fadeInOut}
        >
            <ModalContainer
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, }}
                onClick={(ev) => { ev.stopPropagation() }}
            >
                {children}
            </ModalContainer>
        </Container>}
    </AnimatePresence>
}

const ModalContainer = styled(motion.div)`
    min-height: 300px;
    max-height: 80%;
    background-color: ${loopStyles.colors.primary};
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    overflow: hidden;
    ${loopStyles.mediaQueries.desktop} {
        min-width: 400px;
        max-width: 800px;
    }
    ${loopStyles.mediaQueries.mobile} {
        flex: 1;
        max-width: 90%;
        margin: 20px;
    }
`

const Container = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`

export default Modal